<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Brochure',

    metaInfo: { title: 'Brochure' },

    extends: View,

    mixins: [
      LoadSections([
        'news-main',
        'news-alt',
        'go-contact-us',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'brochure',
      },
    },
  }
</script>
